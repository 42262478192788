import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router-dom";
import KRWPopup from "./KRWPopup";
import { API_URL2, getUser, getAccess, postTokenCheck } from "../../utils/api";

const KRWWithdrawal = ({ selectedToken }) => {
  const { t } = useTranslation();
  const [bankName, setBankName] = useState("");
  const [account, setAccount] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);

  const [popup, setPopup] = useState(0);
  const [clear, setClear] = useState(false)
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  const [tokenBal, settokenBal] = useState({
    1: userinfo.BtcValue,
    2: userinfo.EthValue,
    3: userinfo.mPandoValue,
    4: userinfo.PandoValue,
  });
  const history = useHistory();

  useEffect(()=>{
    (amount >= 50000) ? setClear(true) : setClear(false)
  },[amount])

  const amountBtn = [1,3,5,10,30,50]

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const check = async () => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const refreshToken = await sessionStorage.getItem('refreshToken')
    const data = {
        userPK : userinfo.id,
        refresh_token : refreshToken
    }

    postTokenCheck(data, logout).then(res => {
        if(res.data.code === '1'){
            setPopup(2);
        }
        else if(res.data.code === '999'){
            getAccess().then(async(res) => {
            if(res.data.code === '1'){
                await sessionStorage.setItem('accessToken', res.data.access_token)
                check();
            }
            else {
                logout();
            }
            })
        }
        else{
            logout();
        }
    })
}

const logout = () => {
    history.push('/');
}
  return (
    <>
      <div className="">
        {/* <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankName")}</h6>
          <input
            value={bankName}
            type="text"
            placeholder="Bank Name"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setBankName(event.target.value)}
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankAccount")}</h6>
          <input
            value={account}
            placeholder="Bank Account"
            type="text"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setAccount(event.target.value)}
          />
        </div> */}

        <div className="text-13 m-2 pt-2 mt-3">
        <h6 className="pb-2">출금금액 <span className="text-13 text-[#6c757d]">(보유 : {Number(userinfo.krw).toLocaleString()}원)</span></h6>
        <div className="flex mb-2" style={{gap : 4, flexWrap : 'wrap'}}>
          {
            amountBtn.map((item, idx) => (
              <div style={{borderRadius : 4, border : '1px solid #ccc', padding : '0.5rem 1rem', cursor:'pointer'}} onClick={()=>setAmount(amount + item*10000)} key={idx}>{item.toLocaleString()}만</div>
            ))
          }
          <div style={{borderRadius : 4, border : '1px solid #ccc', padding : '0.5rem 1rem', cursor:'pointer'}} onClick={()=>setAmount(0)}>초기화</div>
        </div>
          <input
            value={amount.toLocaleString()}
            type="text"
            placeholder="Amount"
            readOnly
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
          />
          <p>최소 출금금액은 50,000원 이상입니다.</p>
        </div>

        {/* <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankUser")}</h6>
          <input
            value={name}
            type="text"
            placeholder="Depositor name"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setName(event.target.value)}
          />
        </div> */}

        <div className="border border-solid border-[#dee2e6] my-6 mx-6"></div>
      </div>

      <div className="px-3 my-3">
        <p className="text-13 text-[#6c757d] mb-4">
          {t("Renting a Canance account")}
        </p>
        <p className="text-13 text-[#6c757d] mb-4">
          {t("If you rent a real-name verified account")}
        </p>
        <p className="text-13 text-[#6c757d] mb-4">
          {t("Withdrawal may be restricted")}
        </p>
        <button
          className={`w-full bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
          onClick={() => {check()}} // onSubmitData()
          // onClick={() => setPopup(2)} // onSubmitData()
          disabled={!clear}
        >
          {t("requestBenefits")}
        </button>
      </div>
      {popup ? (
        <KRWPopup
          popup={popup}
          setPopup={setPopup}
          selectedToken={selectedToken}
          bankName={bankName}
          account={account}
          name={name}
          amount={amount}
        />
      ) : null}
    </>
  );
};

export default KRWWithdrawal;
