import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import WalletMyAssets from "../components/wallet/WalletMyAssets";
import BuyPage from "../components/wallet/BuyPage";
import WalletPoint from "../components/wallet/WalletPoint";
import WalletDepositsWithdrawals from "../components/wallet/WalletDepositsWithdrawals";
import WalletTabMenuList from "../components/wallet/WalletTabMenuList";
import axios from "axios";
import { Token } from "../data/tokenList";
import { getUser, UpdateAddr, getAccess, postTokenCheck } from "../utils/api";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import Preparing from "./preparing";
import { API_URL2 } from "../utils/api";
import { userLogoutAction } from "../redux/actions/userAction";

const WALLET_TAB_MENU = [
  {
    id: 1,
    title: "Coin List",
  },
  {
    id: 2,
    title: "wallet.point",
  },
  {
    id: 3,
    title: "wallet.purchase",
  },
];

const WalletScreen = () => {
  //console.log("Token: ", Token[1]);
  const [addrBool, setAddrBool] = useState(false);
  const [navTab, setNavTab] = useState(1);
  const [selectedToken, setSelectedToken] = useState(Token[1]);
  const [userinfo, setUserinfo] = useState({...JSON.parse(localStorage.getItem('userinfo'))});
  // const data = JSON.parse(localStorage.userinfo);
  const { t } = useTranslation();

  const history = useHistory();
  let dispatch = useDispatch();

  const updateUser = async() => {
    const datachk = localStorage.getItem("userinfo");
    if(datachk !== 'undefined' && datachk !== null){
        const userinfo = JSON.parse(localStorage.getItem("userinfo"));
        const refreshToken = await sessionStorage.getItem('refreshToken')
        const data = {
        userPK : userinfo.id,
        refresh_token : refreshToken
        }

        postTokenCheck(data, logout).then(res => {
        if(res.data.code === '1'){
            getUser({userPK : userinfo.id}).then(res => {
            if(res.data.code === '1'){
                localStorage.setItem("userinfo", JSON.stringify(res.data.user));
                setUserinfo({...res.data.user});
            }
            })
        }
        else if(res.data.code === '999'){
            getAccess().then(async(res) => {
            if(res.data.code === '1'){
                await sessionStorage.setItem('accessToken', res.data.access_token)
                updateUser();
            }
            else {
                logout();
            }
            })
        }
        else{
            logout();
        }
        })
    }
}

const logout = () => {
  history.push('/');
}

  const [addr, setAddr] = useState('');
  const handlLbank = (e) => {
    setAddr(e.target.value);
  }

  const postAddr = () => {
    if(addrBool){
      const body = {
        userPK : userinfo.id,
        addr : addr
      }
      UpdateAddr(body).then(res => {
        localStorage.setItem("userinfo", JSON.stringify(res.data.user));
        setUserinfo({...res.data.user});
      })
    }
  }
  
  useEffect(()=>{
    updateUser();
    initToken();
  },[navTab])

  const initToken = () => {
    $.ajax({ 
      url: `${API_URL2}/myWalletAllBalance`, 
      type: "POST", 
      data: { 'userPK': userinfo.id}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        // console.log(result)
        
        localStorage.setItem('lfitVal', result.lfitVal)
        localStorage.setItem('ethVal', result.ethVal)
        localStorage.setItem('krwVal', result.krwVal)
        localStorage.setItem('point', result.point)
        localStorage.setItem('encykl', result.encykl)
        localStorage.setItem('viwVal', result.viwVal)
        localStorage.setItem('usdtVal', result.usdtVal)
        localStorage.setItem('btcVal', result.btcVal)
        localStorage.setItem('xrpVal', result.xrpVal)

      }, error: function (error) { 
        console.log(error)
      } 
    })
  }
  return (
    <Wrapper>
      <div className="border border-gray-300 rounded-sm mt-4 w-full md:w-1/2 mx-auto flex items-center justify-evenly">
        {/* <label htmlFor="lbank">엘뱅크 지갑 주소 : </label>
        <input
          readOnly = {addrBool?false:true}
          disabled = {addrBool?false:true}
          type="text"
          id="lbank"
          name="lbank"
          defaultValue={userinfo.lbankAddr}
          placeholder={'Lbank 주소'}
          onChange={handlLbank}
          className="w-1/2 focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500 disabled:bg-slate-200 text-center"
          style={addrBool?{border:'1px solid red'}:{border:'1px solid transparent'}}
        />
        <button className="" onClick={()=>{
          setAddrBool(!addrBool); 
          document.getElementById('lbank').focus();
          postAddr();
          }}>{addrBool?'저장하기':'수정하기'}</button> */}
      </div>
      <div className="break-words border border-solid border-dark/0.125 rounded-sm mb-16 w-full md:w-1/2 mx-auto">
        <ul className="flex justify-center items-center bg-black/5 border-black/0.125 text-center cursor-pointer">
          {WALLET_TAB_MENU.map((list) => {
            return (
              <li
              key={list.id}
              className={`${
                navTab === list.id
                  ? `w-1/2 text-13 border-b border-solid border-[#333742] py-2 `
                  : `w-1/2 text-[#6c757d] text-13 py-2`
              }`}
              onClick={() => {setNavTab(list.id);}}
            >
              {t(list.title)}
            </li>
              // <WalletTabMenuList
              //   key={list.id}
              //   list={list}
              //   navTab={navTab}
              //   setNavTab={setNavTab}
              //   width="w-1/2"
              // />
            );
          })}
        </ul>
        {
          navTab === 1 ? 
          <WalletMyAssets
            setSelectedToken={setSelectedToken}
            setNavTab={setNavTab}
            userinfo={userinfo}
            navTab={navTab}
          />:
          navTab === 2 ? 
          <WalletPoint />:
          <BuyPage />
        }
      </div>
    </Wrapper>
  );
};

export default WalletScreen;
