import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KRWPopup from "./KRWPopup";

import History from "./History";
import $ from "jquery";
import { API_URL2 } from "../../utils/api";

const KRWHistory = ({ navTab, subNavTab }) => {
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [popup, setPopup] = useState(0);
  const setPopupProps = setPopup;
  const popupProps = popup;
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('2');

  const optionValueHandler = (e) => {
    setSort(e.target.value);
  };

  useEffect(()=>{
    let walletType = 1;
    setData([])
    setLoading(true)
    console.log('sort >>>', sort)
    $.ajax({ 
      url: `${API_URL2}/krwWalletList`, 
      type: "POST", 
      data: { 'userPK': userinfo.id, 'sort' : sort, 'walletType': '2'}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log('sodur <>>>>>', result)
        setData(result.krwWalletlist)
        setLoading(false)
      }, error: function (error) { 
        console.log(error)
        setLoading(false)
      } 
    })
  },[sort])

  return (
    <>
      <div className="flex border-b border-solid border-dark/0.125 p-2" style={{justifyContent : 'space-between', alignItems : 'flex-end'}}>
        <span className="text-xs"> (단위 : 원)</span>
        <select
          onChange={optionValueHandler}
          name="history"
          disabled={loading}
          id="history"
          className="text-sm border border-solid border-dark/0.125 rounded-l py-1 pl-1"
        >
          {/* <option value="9">{t("All")}</option> */}
          {/* 3 - 출금, 2 - 수당, 1 - 투자금, 999 - 반려 */}
          <option value="2">{t("KRWtabMenu5")}</option>
          <option value="1">{t("KRWtabMenu2")}</option>
          <option value="3">{t("KRWtabMenu1")}</option>
        </select>
      </div>
      {/* 수당 */}
      {sort === '2' &&
      <div className="flex" style={{
        position: 'sticky',
        top: '63px',
        backgroundColor: '#333',
        color: 'white',
        justifyContent :'space-between',
        alignItems : 'center',
        padding: "1vw 1vw"       
      }}>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>날짜</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>데일리</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>롤업매칭</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>직급</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>관리비</p>
      </div>}
      {/* 투자금 */}
      {
      (sort === '1') &&
      <div className="flex" style={{
        position: 'sticky',
        top: '63px',
        backgroundColor: '#333',
        color: 'white',
        justifyContent :'space-between',
        alignItems : 'center',
        padding: "1vw 1vw"       
      }}>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>신청날짜</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>처리날짜</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>상태</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>금액</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>반려내용</p>
      </div>}
      {/* 출금 */}
      {
      (sort === '3') &&
      <div className="flex" style={{
        position: 'sticky',
        top: '63px',
        backgroundColor: '#333',
        color: 'white',
        justifyContent :'space-between',
        alignItems : 'center',
        padding: "1vw 1vw"       
      }}>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>신청날짜</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>처리날짜</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>상태</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>금액</p>
        <p className="text-xs xl:text-l" style={{flex: 1, textAlign : 'center'}}>반려내용</p>
      </div>}

      {
        loading &&     
        <div style={{ padding: "1vw 1vw", display: "block", borderBottom: "1px solid #f5f5f5",}}>
          데이터를 불러오는 중입니다.
        </div>
      }
      {(data && data.length > 0 )? data.map((data, i) => {
        return (
          <History
            data={data}
            key={i}
            setPopup={setPopupProps}
            popup={popupProps}
            value={i}
            sort={sort}
          />
        );
      }):
      !loading && <div style={{ padding: "1vw 1vw", display: "block", borderBottom: "1px solid #f5f5f5",}}>
        데이터가 없습니다.
      </div>
    }
    </>
  );
};

export default KRWHistory;
