import React, { useEffect, useState, useRef } from "react";
import { Token } from "../../data/tokenList";
import WalletTokenList from "./WalletTokenList";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LFITBuyPopup from "./LFITBuyPopup";
import $ from "jquery";
import { API_URL2, getUser, getAccess, postTokenCheck } from "../../utils/api";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const WalletPoint = ({ }) => {

  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  const { t } = useTranslation();
  const [id, setId] = useState('');
  const [balance, setBalance] = useState(0);
  const [clear, setClear] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory();
  const [info, setInfo] = useState({
    krw : Number(userinfo.krw),
    point : Number(userinfo.point),
  })

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

useEffect(()=>{
  if(refresh){
    setRefresh(false)
    if (inputRef1.current) {
      inputRef1.current.value = '';
    }
    if (inputRef2.current) {
      inputRef2.current.value = '';
    }

    setBalance(0)
    setId('')
    setInfo({
      krw : Number(JSON.parse(window.localStorage.getItem("userinfo")).krw),
      point : Number(JSON.parse(window.localStorage.getItem("userinfo")).point)
    })
  }
}, [refresh])

  useEffect(() => {
    (balance > 0 && id) ? setClear(true) : setClear(false)
  }, [balance, id]);

  const pointP2P = async() => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const refreshToken = await sessionStorage.getItem('refreshToken')
    const data = {
      userPK : userinfo.id,
      refresh_token : refreshToken
    }

    postTokenCheck(data, logout).then(res => {
      if(res.data.code === '1'){
        if(!id){
          alert('아이디를 입력해주세요')
        }
        else if(!balance){
          alert('포인트를 입력해주세요')
        }
        else if(balance < 10000){
          alert('포인트 출금은 10,000포인트 이상부터입니다.')
        }
        else if(id && balance){
          $.ajax({ 
            url: `${API_URL2}/pointP2P`, 
            type: "POST", 
            data: { 'userPK': userinfo.id, "otherUserName" : id, "balance" : balance}, 
            dataType: "json", 
            async: true, 
            success: function (result) { 
              console.log(result) 
              if(result.code === '1'){
                updateUser();
              }
              else if(result.code === '8'){
                alert('존재하지 않는 아이디 입니다.')
      
              }
              else if(result.code === '9'){
                alert('보유 포인트가 부족합니다.')
              }
            }, error: function (error) { 
              console.log(error) 
            } 
          })
        }
      }
      else if(res.data.code === '999'){
        getAccess().then(async(res) => {
        if(res.data.code === '1'){
            await sessionStorage.setItem('accessToken', res.data.access_token)
            pointP2P();
        }
        else {
            logout();
        }
        })
    }
      else{
        logout();
      }
    })
  }

  const logout = () => {
    history.push('/');
  }

  const updateUser = () => {
    const datachk = localStorage.getItem("userinfo");
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const data = {
      userPK : userinfo.id,
    }
    getUser(data).then(res => {
      localStorage.setItem("userinfo", JSON.stringify(res.data.user));
      localStorage.setItem("point", res.data.user.point);
      alert('포인트 전송 완료')
      setRefresh(true)
    })
  }
  return (
    <>
      <div>
        <div className="text-13 m-2 pt-2 mt-3">
        <p className="text-center mb-3">포인트 전송</p>
        

            <h6 className="pb-2">아이디</h6>
            <input
              ref={inputRef1}
              type="text"
              onChange={(e) => {setId(e.target.value)}}
              placeholder="ID"
              className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2 mb-3"
            />
            {/* <p>최소 출금금액은 50,000원 이상입니다.</p> */}

            <h6 className="pb-2">보낼 포인트 <span className="text-13 text-[#6c757d]">(보유 : {info.point.toLocaleString()}원)</span></h6>
            <input
              ref={inputRef2}
              type="number"
              disabled={Number(info.point) < 10000}
              min={0}
              step={100}
              max={Number(info.point)}
              onChange={(e) => {setBalance(e.target.value)}}
              placeholder="Amount"
              className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            />
            <p>최소 전송금액은 10,000포인트 이상입니다.</p>
        </div>

        <button
          className={`mt-3 w-full bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
          onClick={() => {pointP2P()}} // onSubmitData()
          disabled={!clear}
        >
          전송하기
        </button>

      </div>
    </>
  );
};

export default WalletPoint;
