import React, { useState, useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import WalletMyAssets from "../../components/wallet/WalletMyAssets";
import WalletLFITBuy from "../../components/wallet/WalletLFITBuy";
import WalletXRPBuy from "../../components/wallet/WalletXRPBuy";
import WalletBTCBuy from "../../components/wallet/WalletBTCBuy";
import WalletENCYKLBuy from "../../components/wallet/WalletENCYKLBuy";
import WalletPoint from "../../components/wallet/WalletPoint";
import WalletVIWBuy from "../../components/wallet/WalletVIWBuy";
import WalletDepositsWithdrawals from "../../components/wallet/WalletDepositsWithdrawals";
import WalletTabMenuList from "../../components/wallet/WalletTabMenuList";
import axios from "axios";
import { Token } from "../../data/tokenList";
import { getUser, UpdateAddr, getAccess, postTokenCheck} from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { API_URL2 } from "../../utils/api";
import Preparing from "../../screens/preparing";
import { userLogoutAction } from "../../redux/actions/userAction";
import { useSelector } from "react-redux";
const WALLET_TAB_MENU = [
{
    id: 1,
    title: "wallet.bitcoin",
},
{
    id: 2,
    title: "wallet.ripple",
},
{
    id: 3,
    title: "wallet.lfitBuy",
},
{
    id: 4,
    title: "wallet.encyklBuy",
},
{
    id: 5,
    title: "wallet.viw",
},
];

const BuyPage = () => {
//console.log("Token: ", Token[1]);
const [addrBool, setAddrBool] = useState(false);
const [navTab, setNavTab] = useState(1);
const [selectedToken, setSelectedToken] = useState(Token[1]);
const [userinfo, setUserinfo] = useState({...JSON.parse(localStorage.getItem('userinfo'))});
// const data = JSON.parse(localStorage.userinfo);
const { t } = useTranslation();

const history = useHistory();

const updateUser = async() => {
    const datachk = localStorage.getItem("userinfo");
    if(datachk !== 'undefined' && datachk !== null){
        const userinfo = JSON.parse(localStorage.getItem("userinfo"));
        const refreshToken = await sessionStorage.getItem('refreshToken')
        const data = {
            userPK : userinfo.id,
            refresh_token : refreshToken
        }

        postTokenCheck(data, logout).then(res => {
        if(res.data.code === '1'){
            getUser({userPK : userinfo.id}).then(res => {
            if(res.data.code === '1'){
                localStorage.setItem("userinfo", JSON.stringify(res.data.user));
                setUserinfo({...res.data.user});
            }
            })
        }
        else if(res.data.code === '999'){
            getAccess().then(async(res) => {
            if(res.data.code === '1'){
                await sessionStorage.setItem('accessToken', res.data.access_token)
                updateUser();
            }
            else {
                logout();
            }
            })
        }
        else{
            logout();
        }
        })
    }
}

const logout = () => {
    history.push('/');
}

const [addr, setAddr] = useState('');
const handlLbank = (e) => {
    setAddr(e.target.value);
}

const postAddr = () => {
    if(addrBool){
    const body = {
        userPK : userinfo.id,
        addr : addr
    }
    UpdateAddr(body).then(res => {
        localStorage.setItem("userinfo", JSON.stringify(res.data.user));
        setUserinfo({...res.data.user});
    })
    }
}
useEffect(()=>{
    updateUser();
},[navTab])

return (
    <Wrapper>
    <div className="break-words border border-solid border-dark/0.125 rounded-sm mb-16 w-full mx-auto">
        <ul className="flex justify-center items-center bg-black/9 border-black/0.125 text-center cursor-pointer">
        {WALLET_TAB_MENU.map((list) => {
            return (
            <li
            key={list.id}
            className={`${
                navTab === list.id
                ? `w-1/2 text-13 border-b border-solid border-[#333742] py-2 `
                : `w-1/2 text-[#6c757d] text-13 py-2`
            }`}
            onClick={() => {setNavTab(list.id);}}
            >
            {t(list.title)}
            </li>
            );
        })}
        </ul>
        {
        navTab === 1 ? 
            // <WalletBTCBuy />:
            <Preparing />:
        navTab === 2 ? 
            // <WalletXRPBuy />:
            <Preparing />:
        navTab === 3 ? 
            <WalletLFITBuy />:
        navTab === 4 ? 
            <WalletENCYKLBuy />:
            <WalletVIWBuy />
        }
        </div>
    </Wrapper>
    );    
};

export default BuyPage;
