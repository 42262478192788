import axios from "axios";

// const API_URL = 'https://server.viw.app';
// const API_URL = 'http://3.39.223.83:9856';
// export const API_URL2 = 'http://3.39.223.83:9856';
// const API_URL = 'http://52.79.192.76:8588';
// export const API_URL2 = 'http://52.79.192.76:8588';
const API_URL = 'http://52.79.192.76:9856';
export const API_URL2 = 'http://52.79.192.76:9856';
// const API_URL = 'https://www.lfit.world';
const header = {
    "Content-Type": 'application/json',
}

// 로그인
export const login = async (reqData) => {
    const url = `${API_URL}/login_web`;
    
    return await axios.post(url, JSON.stringify(reqData), {headers: header})
};

// 회원가입
export const signup = async (reqData) => {
    const url = `${API_URL}/signup`;
    
    return await axios.post(url, JSON.stringify(reqData), {headers: header})
};

// 회원가입
export const signup_web = async (reqData) => {
    const url = `${API_URL}/signup_web`;
    
    return await axios.post(url, JSON.stringify(reqData), {headers: header})
};

// 이메일체크
export const checkEmail = async (reqData) => {
    const url = `${API_URL}/checkEmail`;
    
    return await axios.post(url, JSON.stringify(reqData), {headers: header})
};

// 페이지마다 유저 데이터 불러오기
export const getUser = async (reqData) => {
    const url = `${API_URL}/updateUserinfo_web`;
    const token = await sessionStorage.getItem('accessToken')
    return await axios.post(url, JSON.stringify(reqData), {
        headers : {
            "Content-Type": 'application/json',
            "Authorization" : `Bearer ${token}`
        }
    })
}

// 페이지마다 유저 데이터 불러오기
export const postTokenCheck = async (reqData, logout) => {
    const url = `${API_URL}/tokenCheck`;
    const token = await sessionStorage.getItem('accessToken')

    if(token){
        return await axios.post(url, reqData, {
            headers : {
                "Content-Type": 'application/json',
                "Authorization" : `Bearer ${token}`
            }
        })
    }
    else{
        logout();
    }
}

// accessToken 재발급
export const getAccess = async () => {
    const url = `${API_URL}/newAccToken`;
    const token = await sessionStorage.getItem('refreshToken')
    return await axios.post(url, {}, {
        headers : {
            "Content-Type": 'application/json',
            "Authorization" : `Bearer ${token}`
        }
    })
}

// 엘뱅크 주소 업데이트
export const UpdateAddr = async (reqData) => {
    const url = `${API_URL}/lbankAddrSubmit`;
    
    return await axios.post(url, JSON.stringify(reqData), {headers: header})
}