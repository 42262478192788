import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import WalletMyAssets from "../components/wallet/WalletMyAssets";
import Extra from "../components/krw/Extra";
import KRWHistory from "../components/krw/KRWHistory";
import Investment from "../components/krw/Investment";
import Tether from "../components/krw/Tether";
import WalletTabMenuList from "../components/wallet/WalletTabMenuList";
import axios from "axios";
import { Token } from "../data/tokenList";
import { getUser, UpdateAddr, getAccess, postTokenCheck } from "../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { API_URL2 } from "../utils/api";
import { userLogoutAction } from "../redux/actions/userAction";

const WALLET_TAB_MENU = [
  {
    id: 1,
    title: "KRWtabMenu1",
  },
  {
    id: 2,
    title: "KRWtabMenu2",
  },
  // {
  //   id: 3,
  //   title: "KRWtabMenu6",
  // },
  {
    id: 4,
    title: "History",
  },
];

const KRWScreen = () => {
  //console.log("Token: ", Token[1]);
  const [addrBool, setAddrBool] = useState(false);
  const [navTab, setNavTab] = useState(1);
  const [selectedToken, setSelectedToken] = useState(Token[1]);
  const [userinfo, setUserinfo] = useState({...JSON.parse(localStorage.getItem('userinfo'))});
  // const data = JSON.parse(localStorage.userinfo);
  const { t } = useTranslation();

  const history = useHistory();
  let dispatch = useDispatch();
  
  const updateUser = async() => {
    const datachk = localStorage.getItem("userinfo");
    if(datachk !== 'undefined' && datachk !== null){
      const userinfo = JSON.parse(localStorage.getItem("userinfo"));
      const refreshToken = await sessionStorage.getItem('refreshToken')
      const data = {
        userPK : userinfo.id,
        refresh_token : refreshToken
      }

      postTokenCheck(data, logout).then(res => {
        if(res.data.code === '1'){
          getUser({userPK : userinfo.id}).then(res => {
            if(res.data.code === '1'){
              localStorage.setItem("userinfo", JSON.stringify(res.data.user));
              setUserinfo({...res.data.user});
            }
          })
        }
        else if(res.data.code === '999'){
          getAccess().then(async(res) => {
            if(res.data.code === '1'){
              await sessionStorage.setItem('accessToken', res.data.access_token)
              updateUser();
            }
            else {
              logout();
            }
          })
        }
        else{
          logout();
        }
      })

    }
  }

  const logout = () => {
    history.push('/');
  }

  const [addr, setAddr] = useState('');
  const handlLbank = (e) => {
    setAddr(e.target.value);
  }
  const [rate, setRate] = useState(0)

  const postAddr = () => {
    if(addrBool){
      const body = {
        userPK : userinfo.id,
        addr : addr
      }
      UpdateAddr(body).then(res => {
        localStorage.setItem("userinfo", JSON.stringify(res.data.user));
        setUserinfo({...res.data.user});
      })
    }
  }

  useEffect(()=>{
    updateUser();

    // 프로그레스 바
    $.ajax({ 
      url: `${API_URL2}/paymentRate`, 
      type: "POST", 
      data: { 'userPK': userinfo.id}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log(result)
        setRate(result.nowRate)
      }, error: function (error) { 
        console.log(error)
      } 
    })
  },[navTab])

  const convertRate = (type) => {
    const temp = rate / 3
    if(temp <= 50){
      return type === 1 ? temp : 0
    }
    
    else if(temp > 50 && temp <= 75){
      return type === 1 ? temp : type === 2 ? temp - 50 : 0
    }
    
    else if(temp > 75){
      return type === 1 ? 50 : type === 2 ? 25 : temp - 75
    }
    
  }

  return (
    <Wrapper>
      <div className="break-words border border-solid border-dark/0.125 rounded-sm mb-16 w-full md:w-1/2 mx-auto">
        <ul className="flex justify-center items-center bg-black/5 border-black/0.125 text-center cursor-pointer">
          {WALLET_TAB_MENU.map((list) => {
            return (
              <li
              key={list.id}
              className={`${
                navTab === list.id
                  ? `w-1/2 text-13 border-b border-solid border-[#333742] py-2 `
                  : `w-1/2 text-[#6c757d] text-13 py-2`
              }`}
              onClick={() => {setNavTab(list.id); updateUser();}}
            >
              {t(list.title)}
            </li>
              // <WalletTabMenuList
              //   key={list.id}
              //   list={list}
              //   navTab={navTab}
              //   setNavTab={setNavTab}
              //   width="w-1/2"
              // />
            );
          })}
        </ul>
        {
        navTab === 1 ? 
        <Extra navTab={navTab}/> : 
        navTab === 2 ? 
        <Investment navTab={navTab}/> : 
        navTab === 3 ? 
        <Tether navTab={navTab}/> : 
        <>
          <div className="px-2 xl:px-10">
            <p className="mt-10">수당 비율 : {(rate)}%</p>
            <div className="w-full bg-gray-200 h-4 mb-10 dark:bg-gray-700">
              <div className="flex">
                <div className="bg-blue-400 h-4" style={{width: `${convertRate(1)}%`}}></div>
                <div className="bg-yellow-400 h-4" style={{width: `${convertRate(2)}%`}}></div>
                <div className="bg-red-400 h-4" style={{width: `${convertRate(3)}%`}}></div>
              </div>
              <div className="flex" style={{position : 'relative'}}>
                <p className="h-4 text-xs" style={{width: '50%', borderLeft : '1px solid #ccc', paddingLeft : '3px'}}>
                  0%
                </p>
                <p className="h-4 text-xs" style={{width: '25%', borderLeft : '1px solid #ccc', paddingLeft : '3px'}}>
                  150%
                </p>
                <p className="h-4 text-xs" style={{width: '25%', borderLeft : '1px solid #ccc', paddingLeft : '3px'}}>
                  225%
                </p>
                <p className="h-4 text-xs" style={{width: '25%', borderRight : '1px solid #ccc', paddingRight : '3px', position:'absolute', right : 0, textAlign : 'right'}}>
                  300%
                </p>
              </div>
            </div>
          </div>
          <KRWHistory navTab={navTab}/>
        </>
        }
      </div>
    </Wrapper>
  );
};

export default KRWScreen;
