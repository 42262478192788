import { useTranslation } from "react-i18next";
import KRWPopup from "./KRWPopup";
import React, { useState } from "react";
import $ from "jquery";
import { API_URL2 } from "../../utils/api";
const History = ({ data, popup, setPopup, value, setCount, sort }) => {
  const { t } = useTranslation();
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  console.log(data)
  const cancel = (wallletPK) => {
    $.ajax({ 
      url: `${API_URL2}/cancelWithdraw`, 
      type: "POST", 
      data: {'userPK': userinfo.id, 'krwWalletPK' : wallletPK}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        if(result.code === '1'){
          alert('정상 취소되었습니다.')
          window.location.reload();
        }
        else if(result.code === '9'){
          alert("시간지나서 취소 불가합니다.");
        }
        else{

        }
      }, error: function (error) { 
        console.log(error) 
      } 
    })
  };

  const cancelInvest = (wallletPK) => {
    $.ajax({ 
      url: `${API_URL2}/wallet_cancel`, 
      type: "POST", 
      data: {'req_type': 'app', 'wallet_id' : wallletPK}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        if(result.code === '1'){
          alert('정상 취소되었습니다.')
          window.location.reload();
        }
        else if(result.code === '9'){
          alert("시간지나서 취소 불가합니다.");
        }
        else{

        }
      }, error: function (error) { 
        console.log(error) 
      } 
    })
  };
  return (
    <>
      <div
        className="flex"
        style={{
          padding: "1vw 1vw",
          borderBottom: "1px solid #f5f5f5",
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {/* <p>{JSON.stringify(data)}</p> */}
        <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>{data.createAt}</p>
        <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>
          {data.status === '1' ? `${data.realPrice}` : ""}
          {sort === '3' && `${data.updateAt}`}
          {sort === '1' && `${data.updateAt}`}
        </p>
        {
          sort === '3' || sort === '1' ?
          <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>
            {
            data.status === '0' ? 
            '확인중' : 
            (data.status === '7' || data.status === '9' || data.status === '9999' || data.status === '99999') ? 
            '완료' : 
            data.status === '99' ? 
            '취소' : 
            '반려'
            }
          </p>:

          <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>
            {data.rollUp ? data.rollUp : ""}
          </p>
        }
        {
          sort !== '2' ?
          <>
            <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>{data.realPrice}</p>
            {
              (sort === '3' && data.status === '0') ?
              <div className="flex justify-center" style={{flex: 1, borderRadius : 4, cursor:'pointer'}} onClick={()=>cancel(data.krwWalletPK)}>
                <span className="text-xs" style={{ border : '1px solid #ccc', padding : '0.2rem 0.5rem'}}>취소</span>
              </div>:
              // 투자금 입금 신청 취소 버튼
              sort === '1' ? 
              <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>{data.rejectText}</p>:
              <>
                <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>{data.rejectText}</p>
              </>
            }
          </>:
          <>
            {/* <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>{data.rankPrice || `${data.realPrice}원`}</p> */}
            <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>{data.rankPrice}</p>
            <p className="text-xs" style={{ flex: 1, textAlign: 'center' }}>
              {data.gmFee && <p>그룹장 : {data.gmFee}</p>}
              {data.centerFee && <p>센터 : {data.centerFee}</p>}
            </p>
          </>
        }
      </div>
    </>
  );
};

export default History;
