import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoEarth } from "react-icons/io5";
import { RiLoginBoxLine } from "react-icons/ri";
import { HiMenu } from "react-icons/hi";
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";
import settingTheme from "../utils/theme";
import Language from "./Language";
import Sidebar from "./Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { getUser, getAccess, postTokenCheck } from "../utils/api";
import { userLogoutAction } from "../redux/actions/userAction";

const Header = ({ location }) => {
  const { t } = useTranslation();

  let isAuthenticated = useSelector((state) => {
    return state.user.isAuthenticated;
  });
  //console.log("isAuthenticated: ", isAuthenticated);

  const [theme, setTheme] = useState(localStorage.getItem("pandoswap_theme"));
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const history = useHistory();
  let dispatch = useDispatch();

  const updateUser = async () => {
    const datachk = localStorage.getItem("userinfo");
    if(datachk !== 'undefined' && datachk !== null){
      const userinfo = JSON.parse(localStorage.getItem("userinfo"));
      const refreshToken = await sessionStorage.getItem('refreshToken')

      const data = {
        userPK : userinfo.id,
        refresh_token : refreshToken
      }

      postTokenCheck(data, logout).then(res => {
        if(res.data.code === '1'){
          getUser({userPK : userinfo.id}).then(res => {
            if(res.data.code === '1'){
              localStorage.setItem("userinfo", JSON.stringify(res.data.user));
            }
          })
        }
        else if(res.data.code === '999'){
          getAccess().then(async(res) => {
            if(res.data.code === '1'){
              await sessionStorage.setItem('accessToken', res.data.access_token)
              updateUser();
            }
            else {
              logout();
            }
          })
        }
        else{
          logout();
        }
      })
    }
  }

  const logout = () => {
    alert('로그인이 만료되었습니다.\n다시 로그인해주세요.');
    dispatch(userLogoutAction());
    window.localStorage.removeItem("userinfo");
    window.sessionStorage.removeItem("accessToken");
    window.sessionStorage.removeItem("refreshToken");
    history.push('/');
  }

  useEffect(()=>{
    console.log('location >>>>', location.pathname)
    if(location.pathname !== 'wallet' || location.pathname !== 'krw'){
      updateUser()
    }
  },[location])

  useEffect(() => {
    settingTheme();
  }, []);

  const darkThemeHandler = () => {
    localStorage.setItem("pandoswap_theme", "dark");
    document.documentElement.classList.add("dark");
    setTheme("dark");
  };

  const lightThemeHandler = () => {
    localStorage.setItem("pandoswap_theme", "light");
    document.documentElement.classList.remove("dark");
    setTheme("light");
  };

  return (
    <header className="w-full fixed top-0 left-0 bg-[#fff] dark:bg-[#212529] z-50 flex justify-center items-center h-[64px] px-4 md:px-0">
      <nav className="max-w-7xl flex justify-between items-center w-full">
        <div className="flex items-center" style={{gap: 12}}>
          <Link to="/">
            <img src="/images/header/icon2.svg" className="h-[32px]" />
          </Link>
          <span className="text-mainColor dark:text-gray-200 text-s font-bold">{userinfo && userinfo.username}</span>
        </div>
        <div className="flex justify-between items-center space-x-4">
        <div className="hidden justify-between items-center space-x-4 md:flex">
            <div className="w-[25%] md:w-[130px]">
            <Link
              to="/"
              className={`flex flex-col items-center text-mainColor dark:text-gray-200 ${
                location.pathname === "/" && "!text-blue-500"
              }`}
            >
              <span className="text-s font-bold">Home</span>
            </Link>
          </div>
          <div className="w-[25%]  md:w-[130px]">
            <Link
              to="/wallet"
              className={`flex flex-col items-center text-mainColor dark:text-gray-200 ${
                location.pathname === "/wallet" && "!text-blue-500"
              }`}
            >
              <span className="text-s font-bold">Wallet</span>
            </Link>
          </div>
          <div className="w-[25%]  md:w-[130px]">
            <Link
              to="/convert"
              className={`flex flex-col items-center text-mainColor dark:text-gray-200 ${
                location.pathname === "/convert" && "!text-blue-500"
              }`}
            >
              <span className="text-s font-bold">Convert</span>
            </Link>
          </div>

          <div className="w-[25%]  md:w-[130px]">
            <Link
              to="/krw"
              className={`flex flex-col items-center text-mainColor dark:text-gray-200 ${
                location.pathname === "/krw" && "!text-blue-500"
              }`}
            >
              <span className="text-s font-bold">KRW</span>
            </Link>
          </div>
          {/* <div className="w-[25%]  md:w-[130px]">
            <Link
              to="/mypage"
              className={`flex flex-col items-center text-mainColor dark:text-gray-200 ${
                location.pathname === "/mypage" && "!text-blue-500"
              }`}
            >
              <span className="text-s font-bold">Profile</span>
            </Link>
          </div> */}
        </div>
          <div className="relative">
            <button
              type="button"
              className="flex items-center space-x-1"
              onClick={() => setShowSelectLanguage(!showSelectLanguage)}
            >
              <IoEarth className="text-2xl text-[#212529] dark:text-gray-200" />
              <span className=" text-sm text-[#212529] dark:text-gray-200">
                {t("lang")}
              </span>
            </button>
            <Language
              showSelectLanguage={showSelectLanguage}
              setShowSelectLanguage={setShowSelectLanguage}
            />
          </div>
            {/* <div className="flex justify-center items-center">
              {theme === "dark" ? (
                <button type="button" onClick={lightThemeHandler}>
                  <BsFillMoonFill className="text-xl text-gray-200" />
                </button>
              ) : (
                <button type="button" onClick={darkThemeHandler}>
                  <BsFillSunFill className="text-xl text-orange-300" />
                </button>
              )}
            </div> */}
          {/* <div>
            {isAuthenticated ? null : (
              <Link to="/login">
                <RiLoginBoxLine className="text-2xl text-[#212529] dark:text-gray-200" />
              </Link>
            )}
          </div> */}
          <div>
            <div onClick={()=>{toggleSidebar(); updateUser();}}>
              <HiMenu className="text-2xl text-[#212529] dark:text-gray-200" />
            </div>
          </div>
        </div>
        <Sidebar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
      </nav>
  
    </header>
  );
};

export default withRouter(Header);
